import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["guidelineWrapper", "guidelineTpl"]

  addGuideline(){
    const newGuideline = this.guidelineTplTarget.innerHTML

    this.guidelineWrapperTarget.insertAdjacentHTML('beforeend', newGuideline)
  }

  removeGuideline(){
    event.currentTarget.closest('[data-guideline]').remove()
  }
}