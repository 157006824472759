import { Controller } from "@hotwired/stimulus";
import initializeTooltips from '../components/app_tooltips';
import { removeContent, projectName } from '../components/utilities';

export default class extends Controller {
  static targets = ['extract', 'descriptionWrapper', 'languageBtn'];

  connect(){
    initializeTooltips(this.element);

    const extractId = this.element.dataset.extractId;
    if(!extractId) return;

    const extracts = this.descriptionWrapperTarget.querySelectorAll(`span[id='${extractId}']`)
    extracts[0].closest('[data-message-wrapper]').scrollIntoView()

    extracts.forEach(extract => extract.classList.add('blinking'))
  }

  scrollToElement(){
    const link = event.currentTarget.dataset.link;
    const extract = this.descriptionWrapperTarget.querySelector(`[data-link='${link}']`)

    extract.closest('[data-message-wrapper]').scrollIntoView()
  }

  // Highlight extract and cluster tag when mouseover (for item#index and item#show)
  highlight(){
    const link = event.currentTarget.dataset.link;

    const elements = document.querySelectorAll(`[data-link='${link}']`);

    elements.forEach(el => {
      if (el.dataset.lockColor === 'false'  || el.dataset.lockColor === undefined) {
        if (el.dataset.mouseout !== '') el.classList.remove(el.dataset.mouseout)
        if (el.dataset.mouseover !== '') el.classList.add(el.dataset.mouseover)
      }
    });
  }

  // Unhighlight extract and cluster tag when mouseout (for item#index and item#show)
  unhighlight(){
    const link = event.currentTarget.dataset.link;

    const elements = document.querySelectorAll(`[data-link='${link}']`);

    elements.forEach(el => {
      if (el.dataset.lockColor === 'false' || el.dataset.lockColor === undefined) {
        if (el.dataset.mouseover !== '') el.classList.remove(el.dataset.mouseover)
        if (el.dataset.mouseout !== '') el.classList.add(el.dataset.mouseout)
      }
    });
  }

  // Update action button selection when clicking on an extract (for item#show)
  addExtractToActionButtons(){
    // Find the id of the selected extract
    const extId = event.currentTarget.id;

    // Reinitialize extract colors
    this.extractTargets.forEach(extract => {
      if (extract.dataset.mouseover !== '') extract.classList.remove(extract.dataset.mouseover);
      if (extract.dataset.mouseout !== '') extract.classList.add(extract.dataset.mouseout);
      extract.dataset.lockColor = false;

      // Lock the style of the selected extract
      if (extract.id !== extId) return;

      if (extract.dataset.mouseover !== '') extract.classList.add(extract.dataset.mouseover);
      if (extract.dataset.mouseout !== '') extract.classList.remove(extract.dataset.mouseout);
      extract.dataset.lockColor = true;
    })

    const data = { selection: { selected_count: 1, selected_ids: [extId] }}
    

    const actionBtnsEvent = new CustomEvent("list-action-button-updated", { detail: { 
      list_action_id: 'item-show-list-bar', 
      list_id: null, 
      data: data } 
    });
    window.dispatchEvent(actionBtnsEvent);
  }

  changeLanguage(){
    // Remove the selected class from all the buttons
    this.languageBtnTargets.forEach(target => target.classList.replace('display-btn-selected', 'display-btn'))

    // Then add the selected class to the current button
    event.currentTarget.classList.add('display-btn-selected')

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/items/change_language`;

    const itemId = this.element.dataset.itemId
    const translated = event.currentTarget.dataset.translated
    const body = { item_id: itemId, translated: translated }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.descriptionWrapperTarget.innerHTML = ''
      // removeContent(this.descriptionWrapperTarget.innerHtml)
      this.descriptionWrapperTarget.insertAdjacentHTML('afterbegin', data['content'] );
      this.connect();
    })
  }
}