import { Controller } from "@hotwired/stimulus"
import ChartJs from "../../components/initialize_graph";

export default class extends Controller {
  static targets = ['chart'];

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    this.initializeChart()
  }

  initializeChart(){    
    if (!this.hasChartTarget) return;

    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    this.historyChart = new ChartJs(this.chartTarget, graphData);
    this.historyChart.drawChart();

    if (!this.pageState().include_volumes) this.historyChart.toggleSeries(1)
  }

  toggleVolumesSeries(){
    const stateEvent = new CustomEvent("state-updated", { detail: { include_volumes: !this.pageState().include_volumes } });
    window.dispatchEvent(stateEvent);

    if (this.pageState().include_volumes){
      event.currentTarget.classList.add('display-btn-selected')
      event.currentTarget.classList.remove('display-btn')
    } else {
      event.currentTarget.classList.remove('display-btn-selected')
      event.currentTarget.classList.add('display-btn')
    }

    this.historyChart.toggleSeries(1)
  }
}