import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';

export default class extends Controller {
  static targets = ["form", "answerWrapper", "question", "answer", "errorMsg", "loader"];

  generateAnswer(){
    this.loaderTarget.classList.remove('hidden')
    this.answerWrapperTarget.classList.add('hidden')
    this.errorMsgTarget.classList.add('hidden')

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/super_admin/params/test_prompt`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    let formData = new FormData(this.formTarget)
    let body = { draft: { guidelines: [] }, autoreply: { guidelines: [] }}

    formData.forEach((value, key) => {
      if (key === 'authenticity_token') return;
      if (key === 'autoreply[guidelines][]'){
        body.autoreply.guidelines.push(value)
      } else if (key === 'draft[guidelines][]'){
        body.draft.guidelines.push(value)
      } else {
        body[key] = value
      }
    })

    fetch(url, {
        method: 'post',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.loaderTarget.classList.add('hidden')

      if (data['error']){
        this.errorMsgTarget.innerHTML = data.error
        this.answerWrapperTarget.classList.add('hidden')
        this.errorMsgTarget.classList.remove('hidden')
      } else {
        this.questionTarget.innerHTML = data.question;
        this.answerTarget.innerHTML = data.answer;
        this.answerWrapperTarget.classList.remove('hidden')
        this.errorMsgTarget.classList.add('hidden')
      }
    })
  }
}
