import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';

export default class extends Controller {
  static targets = ['button', 'createBtn', 'createForm', 'favoriteName', 'dropdown', 'list', 'itemListTpl']

  // Select favorite filter from the list of favorites
  chooseFavorite(){
    this.changeFilterState('inactive'); // Deactive filters to avoid calling filter action when performing changes

    // Replace current filters by favorite filter
    let currentFilters = JSON.parse(document.querySelector('[data-controller="filter-bar"]').dataset.filters)
    const favoriteFilters = JSON.parse(event.currentTarget.dataset.filters);
    let specialFilter = {};

    // In perimeter pages, add the perimeter filter before updating data
    const perimeterContainer = document.querySelector('[data-controller="perimeter-select"]')
    if (perimeterContainer) specialFilter = currentFilters.filter(el => el['operator'] === 'perimeter')[0]

    const totalFilters = Object.keys(specialFilter).length === 0 ? favoriteFilters : [...favoriteFilters, specialFilter]
    document.querySelector('[data-controller="filter-bar"]').dataset.filters = JSON.stringify(totalFilters);

    setTimeout(() => {
      this.changeFilterState('active')

      // Update filters display
      const customEvent = new CustomEvent("add-favorite-filters");
      window.dispatchEvent(customEvent);
    }, 10);

    if (event) event.stopPropagation()
    this.buttonTarget._tippy.hide()
  }

  changeFilterState(newState){
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    filterBar.dataset.filterState = newState;
  }

  // Show the form that creates the favorite
  showCreationForm(){
    this.createBtnTarget.classList.add('hidden');
    this.createFormTarget.classList.remove('hidden');
  }

  // Hide the form that creates the favorite
  hideCreationForm(){
    this.createFormTarget.classList.add('hidden');
    this.createBtnTarget.classList.remove('hidden');
  }

  // Create the favorite
  createFavorite(){
    let target = event.currentTarget;

    let url = `${window.location.origin}/${projectName(window.location.pathname)}/favorites`;
    const body = {
      name: this.createFormTarget.querySelector('input[id="name"]').value,
      filters: JSON.parse(document.querySelector('[data-controller="filter-bar"]').dataset.filters)
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => {
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

        if (data['flash']['error']) return;

        let new_favorite = data['new_favorite'];
        let content = this.itemListTplTarget.innerHTML;
        content = content.replace(/FAVORITE_NAME/g, new_favorite['name'])
          .replace(/data-filters="FAVORITE_FILTERS"/g, `data-filters='${JSON.stringify(new_favorite['filters'])}'`)
          .replace(/FAVORITE_ID/g, new_favorite['id']);

        this.listTarget.insertAdjacentHTML('beforeend', content);

        // Hide createForm
        this.createFormTarget.classList.add('hidden');
        this.createBtnTarget.classList.remove('hidden');

        this.toggleListBorder();
      });
  }

  // Click on the trash to destroy the favorite
  deleteFavorite(){
    event.stopPropagation();

    let target = event.currentTarget;
    let id = target.dataset.id
    let url = `${window.location.origin}/${projectName(window.location.pathname)}/favorites/${id}`;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(data => {
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

        if (data['flash']['error']) return;

        target.parentElement.remove();

        this.toggleListBorder();
      })
  }

  toggleListBorder(){
    if (this.listTarget.childElementCount === 0) {
      this.listTarget.classList.remove('pb-2', 'border-b')
    } else {
      this.listTarget.classList.add('pb-2', 'border-b')
    }
  }
}
