import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  switchTab(){
    const currentMode = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState).mode
    const newMode = event.currentTarget.dataset.mode;
    if(currentMode === newMode) return;

    this.buttonTargets.forEach(btn => btn.classList.remove('selected'))
    event.currentTarget.classList.add('selected')

    const stateEvent = new CustomEvent("state-updated", { detail: { mode: newMode } });
    window.dispatchEvent(stateEvent);

    const tabEvent = new CustomEvent("tab-updated", { detail: { mode: newMode } });
    window.dispatchEvent(tabEvent);
  }
}
