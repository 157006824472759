import { Controller } from "@hotwired/stimulus"
import { projectName, removeContent } from '../components/utilities';
import initializeSelect from "../components/initialize_select";
import initializeTooltips from '../components/app_tooltips';

export default class extends Controller {
  static targets = ['dropdown', 'rows', 'row', 'rowTpl', 'extractText']

  // Event handlers
  splitExtractShowDropdownHandler = (event) => this.showDropdown(event.detail.extract_id)

  connect(){
    window.addEventListener('split-extract-show-dropdown', this.splitExtractShowDropdownHandler)
    initializeTooltips(this.element);
  }

  disconnect(){
    window.removeEventListener('split-extract-show-dropdown', this.splitExtractShowDropdownHandler)
  }

  showDropdown(extract_id){
    event.preventDefault()

    // The request to showDropdown comes from app_tooltips triggers, so we need to first check that the request came from the same extract
    if (extract_id !== this.element.dataset.extractId) return; 

    const extract = this.extractTextTarget.innerText;
    const hightLightedText = window.getSelection().toString();
    const startIndex = extract.indexOf(hightLightedText)

    // If nothing is highlighted  --> do nothing
    if (hightLightedText === -1) return;

    const endIndex = startIndex + hightLightedText.length

    // Prepare the different sub_extracts
    let subExtracts = []
    if (startIndex > 0) {
      let description = extract.substr(0, startIndex);
      subExtracts.push({
        description: description.trim(),
        positions: this.positions(description, 0, startIndex)
      })
    }
    subExtracts.push({
      description: hightLightedText.trim(),
      positions: this.positions(hightLightedText, startIndex, endIndex)
    })
    if (endIndex < extract.length) {
      const description = extract.substr(endIndex)
      subExtracts.push({
        description: description.trim(),
        positions: this.positions(description, endIndex, extract.length)
      })
    }

    // Remove empty values for the case when the selected text is marginal(start or end of the extract) and preeceded or followed by white space
    subExtracts = subExtracts.filter(element => element.description !== "")

    // Prepare dropdown
    removeContent(this.rowsTarget)

    subExtracts.forEach(subExtract => {
      let content = this.rowTplTarget.innerHTML;
      const extractText = subExtract['description']
      const extractSum = extractText.length < 40 ? extractText : `${extractText.substr(0, 20)}...${extractText.substr(extractText.length - 20)}`
      content = content.replace(/EXTRACT_VALUE/g, extractText)
                       .replace(/EXTRACT_TEXT/g, extractSum)
                       .replace(/EXTRACT_POSITION/g, JSON.stringify(subExtract['positions']))

      this.rowsTarget.insertAdjacentHTML('beforeend', content);

      const select = this.rowsTarget.lastElementChild.querySelector('select')
      const selectData = JSON.parse(select.dataset.options)

      const tomInstance = initializeSelect({
        selector: select,
        otherSettings: {
          options: selectData['options'],
          optgroups: selectData['optgroups'],
          items: JSON.parse(select.dataset.items),
          onItemAdd: (value, item) => {
            tomInstance.blur()
          }
        }
      });
    })
  }

  // Define positions of each subExtract (remove right and left spaces if present)
  positions(description, startIndex, endIndex){
    const leftSpacesCount = description.length - description.trimLeft().length
    const rightSpacesCount = description.length -description.trimRight().length

    return [startIndex + leftSpacesCount, endIndex - rightSpacesCount]
  }

  splitExtract(){
    // Add spinner to the submit button
    const icon = event.currentTarget.firstElementChild;
    icon.classList.remove('hidden');

    const newExtracts = this.rowTargets.map(row => {
      const extract = row.querySelector('span').dataset.value;
      const positions = JSON.parse(row.querySelector('span').dataset.position);
      const clusterId = row.querySelector('select').tomselect.getValue()
      return {description: extract, cluster_id: clusterId, positions: positions}
    })

    const pageState = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/extracts/split`;
    const body = {
      page_state: pageState,
      row_id: event.currentTarget.closest('tr').id,
      extract_id: this.element.dataset.extractId,
      new_extracts: newExtracts,
      list_id: event.currentTarget.closest('[data-controller="list"]').id
    }

    fetch(url, {
        method: 'POST',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
      const listRow = data['list_row']

      if (listRow) {
        // Find row
        const row = document.querySelector(`#${listRow['row_id']}`)

        // Add new row under the one (if present)
        if (listRow['list_row']) row.insertAdjacentHTML('afterend', listRow['list_row'])
        
        // Delete former row
        row.remove();
      }    
    });
  }
}
