import { Controller } from "@hotwired/stimulus"
import MicroModal from "micromodal";
import { projectName } from '../components/utilities';
import { openModal, checkConsistency } from "../components/modal";
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ["itemsList", "itemsModal", "selectedItems",
                    "createCluster", "deleteItemsModal", "addToClusterModal", "addToClusterSelect", "removeFromClusterModal"];
  
  // Event handlers
  filterDataUpdateHandler = (event) => this.updateList(event.detail.data)

  connect(){
    window.addEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  updateList(data){
    if (data['url']){
      window.location.href = data['url']
      return;
    }

    if (data['refreshed_list']){
      const list = document.querySelector('#items_index_items_list');
      list.insertAdjacentHTML('beforebegin', data['refreshed_list'])
      list.remove();
    }

    if (data['flash']){
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    }
  }

  securityCheck(){
    const input = this.activeModal.querySelector('#security_check');
    const submit = this.activeModal.querySelector('#submit');

    checkConsistency(input, submit);
  }

  openModal(id) {
    MicroModal.show(id);
  }

  // DELETE ITEMS______________________________________________________________________________________________________________

  // Open the modal that ask for the change
  deleteItemsModal(){
    this.activeModal = this.deleteItemsModalTarget;
    openModal(this.activeModal, 10, event.currentTarget);
  }

  // Send the request to delete items
  deleteItems(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/items/delete_many`;
    const verb = "DELETE";
    const body = JSON.parse(this.activeModal.dataset.requestData);

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // ADD EXTRACT TO CLUSTER____________________________________________________________________________________________________

  // Open the modal that ask for the change
	addToClusterModal(){
    const tomInstance = this.addToClusterSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    // Initialize clusters selection for add_to_cluster modal
    initializeSelect({
      selector: this.addToClusterSelectTarget,
      config: JSON.parse(this.addToClusterSelectTarget.dataset.config),
      otherSettings: {
        searchField: ['cluster', 'macro_cluster'],
        render: {
          option: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                      ${(data.state === 'validated') ? '<i class="fa-light fa-circle-check text-sm text-green-500"></i>' : ''}
                    </div>`
          },
          item: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                    </div>`
          }
        }
      }
    });

		this.activeModal = this.addToClusterModalTarget;
  	openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to add selected extracts to the cluster
	addToCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		const cluster_name = this.addToClusterSelectTarget.value;

		if (cluster_name === ""){
			// Show error message
			this.activeModal.querySelector("[data-flash]").classList.remove('hidden')
      return;
    }

    // Hide error message
    this.activeModal.querySelector("[data-flash]").classList.add('hidden')

    // Launch request
    const extractId = JSON.parse(this.activeModal.dataset.requestData).data.selection.selected_ids[0]
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/extracts/${extractId}/add_cluster`;
    const verb = "POST";
    const body = { cluster_name: cluster_name };

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
	}

  // REMOVE EXTRACTS FROM A SPECIFIC CLUSTER____________________________________________________________________________

	// Open the modal that ask for the change
	removeFromClusterModal(){
		this.activeModal = this.removeFromClusterModalTarget;
		openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to change the cluster of selected extracts
	removeCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		// Launch request
    const extractId = JSON.parse(this.activeModal.dataset.requestData).data.selection.selected_ids[0]
		const url = `${window.location.origin}/${projectName(window.location.pathname)}/extracts/${extractId}/remove_cluster`;
		const verb = "POST";
    const body = {};

		this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
	}

  // AJAX CALL TO UPDATE CLUSTERS_______________________________________________________________________________________

	// AJAX call to update cluster or extracts
	AJAXcall(url, verb, body){
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
		    .content.value;
		fetch(url, {
		    method: verb,
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      window.location.href = data["url"];
		});
	}
}
